<template>
  <div class="overlay">
    <div class="container text-center col-11 col-xl-7 col-lg-8 col-md-9 h-100 rounded-3 py-3 shadow">
      <div class="rounded shadow py-2 form">
        <div>
          <h4 class="my-0">Informasi Kelulusan Siswa SMK PGRI 1 KEDIRI</h4>
          <h4 class="my-0">Tahun Pelajaran {{ taAktif.nama }}</h4>
        </div>
      </div>
      <div v-if="!userData">
        <login-form />
      </div>
      <div v-else class="rounded shadow mt-3 p-2 form">
        <div class="row row-cols-auto justify-content-between">
          <h5>Data Peserta Didik</h5>
          <div>
            <button class="btn btn-sm btn-outline-danger rounded d-flex p-1" @click="logout">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-power"
                viewBox="0 0 16 16">
                <path d="M7.5 1v7h1V1z" />
                <path d="M3 8.812a5 5 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812" />
              </svg>
            </button>
          </div>
        </div>
        <table class="table table-sm table-striped text-start shadow">
          <tbody class="small">
            <tr>
              <td>Nama</td>
              <td class="text-end">:</td>
              <th>{{ userData.nama }}</th>
            </tr>
            <tr>
              <td>Tempat dan Tanggal Lahir</td>
              <td class="text-end">:</td>
              <th>{{ userData.ttl }}</th>
            </tr>
            <tr>
              <td>Nomor Induk Siswa</td>
              <td class="text-end">:</td>
              <th>{{ userData.nis }}</th>
            </tr>
            <tr>
              <td>Nomor Induk Siswa Nasional</td>
              <td class="text-end">:</td>
              <th>{{ userData.nisn }}</th>
            </tr>
            <tr>
              <td>Program Keahlian</td>
              <td class="text-end">:</td>
              <th>{{ userData.progli }}</th>
            </tr>
            <tr>
              <td>Kompetensi Keahlian</td>
              <td class="text-end">:</td>
              <th>{{ userData.komli }}</th>
            </tr>
          </tbody>
        </table>
        <div class="hstack gap-1 justify-content-center">
          <button v-if="ukkOff || tampilkan == 'ukk'" class="btn btn-primary" disabled>
            <small>Info Kelulusan UKK</small>
            <div v-if="ukkOff">
              <hr class="my-0">
              <small>{{ timerUkk }}</small>
            </div>
          </button>
          <button v-else class="btn btn-primary" @click="getHasilUkk">
            <small>Info Kelulusan UKK</small>
          </button>
          <div class="vr"></div>
          <button v-if="sklOff || tampilkan == 'skl'" class="btn btn-success" disabled>
            <small>Info Kelulusan Sekolah</small>
            <div v-if="sklOff">
              <hr class="my-0">
              <small>{{ timerSkl }}</small>
            </div>
          </button>
          <button v-else class="btn btn-success" @click="getHasilSkl">
            <small>Info Kelulusan Sekolah</small>
          </button>
        </div>
        <div v-if="tampilkan == 'ukk'" class="rounded shadow mt-3 p-2 form">
          <h4>Selamat!</h4>
          <p class="small">Anda dinyatakan lulus Uji Kompetensi Keahlian (UKK) dengan predikat :</p>
          <h3 class="text-primary fw-bold">{{ hasilUkk }}</h3>
        </div>
        <div v-else-if="tampilkan == 'skl'" class="rounded shadow mt-3 p-2 form">
          <h4>Selamat!</h4>
          <p class="small">Berdasarkan kriteria kelulusan SMK PGRI 1 KEDIRI Tahun Pelajaran {{ taAktif.nama }}, maka
            Anda dinyatakan :</p>
          <h3 class="text-success fw-bold">{{ hasilSkl }}</h3>
          <hr />
          <p class="small">Untuk mengunduh Surat Keterangan Lulus (SKL), silahkan masukkan token yang dapat diperoleh
            dari wali kelas.
          </p>
          <form @submit.prevent="downloadSkl" ref="formToken">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Masukkan Token" v-model="token" required>
                <button class="btn btn-outline-secondary fw-bold" type="submit">Unduh SKL</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'
// import jsPDF from 'jspdf'
export default {
  name: 'HomeView',
  components: {
    LoginForm
  },
  data() {
    return {
      ukkOff: true,
      timerUkk: null,
      intervalUkk: null,
      sklOff: true,
      timerSkl: null,
      intervalSkl: null,
      hasilUkk: null,
      hasilSkl: null,
      tampilkan: null,
      token: null
    }
  },
  computed: {
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
    username() {
      return this.$store.getters.getUsername
    },
    password() {
      return this.$store.getters.getPassword
    },
    userData() {
      return this.$store.getters.getUserData
    },
    waktuUkk() {
      return this.$store.getters.getWaktuUkk
    },
    waktuSkl() {
      return this.$store.getters.getWaktuSkl
    },
  },
  mounted() {
    this.intervalUkk = setInterval(this.startTimerUkk, 1000);
    this.intervalSkl = setInterval(this.startTimerSkl, 1000);
  },
  watch: {},
  methods: {
    startTimerUkk() {
      const countDownToTime = new Date(this.waktuUkk);
      const timeNow = new Date().getTime();
      const timeDifference = countDownToTime - timeNow;
      if (timeDifference <= 0) {
        this.ukkOff = false;
        clearInterval(this.intervalUkk);
      } else {
        const millisecondsInOneSecond = 1000;
        const millisecondsInOneMinute = millisecondsInOneSecond * 60;
        const millisecondsInOneHour = millisecondsInOneMinute * 60;
        const millisecondsInOneDay = millisecondsInOneHour * 24;
        const differenceInDays = timeDifference / millisecondsInOneDay;
        const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
        const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
        const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
        const remainingDays = Math.floor(differenceInDays);
        const remainingHours = Math.floor(remainderDifferenceInHours);
        const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
        const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
        this.timerUkk = remainingDays + " Hari, " + remainingHours + " Jam, " + remainingMinutes + " Menit, " + remainingSeconds + " Detik";
      }
    },
    startTimerSkl() {
      const countDownToTime = new Date(this.waktuSkl);
      const timeNow = new Date().getTime();
      const timeDifference = countDownToTime - timeNow;
      if (timeDifference <= 0) {
        this.sklOff = false;
        clearInterval(this.intervalSkl);
      } else {
        const millisecondsInOneSecond = 1000;
        const millisecondsInOneMinute = millisecondsInOneSecond * 60;
        const millisecondsInOneHour = millisecondsInOneMinute * 60;
        const millisecondsInOneDay = millisecondsInOneHour * 24;
        const differenceInDays = timeDifference / millisecondsInOneDay;
        const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
        const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
        const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
        const remainingDays = Math.floor(differenceInDays);
        const remainingHours = Math.floor(remainderDifferenceInHours);
        const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
        const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
        this.timerSkl = remainingDays + " Hari, " + remainingHours + " Jam, " + remainingMinutes + " Menit, " + remainingSeconds + " Detik";
      }
    },
    async getHasilUkk() {
      let memuat = this.$loading.show({
        loader: 'bars'
      });
      try {
        const response = await this.axios.post('/kelulusan/ukk', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id,
          username: this.username,
          password: this.password,
        });
        if (response.data) {
          this.hasilUkk = response.data.ukk;
          this.tampilkan = 'ukk';
        }
      } catch (err) {
        if (err.response) {
          this.$swal({
            title: 'Gagal',
            text: err.response.data.error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          console.log(err);
          this.$swal({
            title: 'Gagal',
            text: err.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async getHasilSkl() {
      let memuat = this.$loading.show({
        loader: 'bars'
      });
      try {
        const response = await this.axios.post('/kelulusan/skl', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id,
          username: this.username,
          password: this.password,
        });
        if (response.data) {
          this.hasilSkl = response.data.skl;
          this.tampilkan = 'skl';
        }
      } catch (err) {
        if (err.response) {
          this.$swal({
            title: 'Gagal',
            text: err.response.data.error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          console.log(err);
          this.$swal({
            title: 'Gagal',
            text: err.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async downloadSkl() {
      let memuat = this.$loading.show({
        container: this.$refs.formToken,
        loader: 'dots'
      });
      try {
        const response = await this.axios.post('/kelulusan/unduh-skl', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id,
          username: this.username,
          password: this.password,
          token: this.token,
        }, {
          responseType: 'blob'
        });
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.userData.nama + '.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          this.token = null;
        }
      } catch (err) {
        if (err.response) {
          this.$swal({
            title: 'Gagal',
            text: err.response.data.error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          console.log(err);
          this.$swal({
            title: 'Gagal',
            text: err.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    logout() {
      this.$store.dispatch('logoutSiswa');
      this.hasilUkk = null;
      this.hasilSkl = null;
      this.tampilkan = null;
    }
  }
}
</script>

<style scoped>
table tr td,
table tr th {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
</style>