<template>
  <div class="rounded shadow mt-3 p-2 form">
    <p class="small" style="text-align: justify;text-justify: inter-word;">Silakan masukkan <em>username</em> dan
      <em>password</em> yang diberikan oleh Wali Kelas untuk mengakses Informasi Kelulusan Uji Kompetensi Keahlian (UKK)
      dan/atau Informasi Kelulusan Sekolah Tahun Pelajaran {{ taAktif.nama }}.
    </p>
    <form @submit.prevent="login" ref="formLogin">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto">
        <input type="text" class="form-control mb-1" placeholder="Masukkan Username" v-model="formLogin.username"
          required>
        <input type="password" class="form-control" placeholder="Masukkan Password" v-model="formLogin.password"
          required>
        <hr />
        <button class="btn btn-secondary w-100" type="submit">Masuk</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  components: {},
  data() {
    return {
      formLogin: {
        username: null,
        password: null
      }
    }
  },
  computed: {
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
  },
  mounted() { },
  watch: {},
  methods: {
    async login() {
      let memuat = this.$loading.show({
        container: this.$refs.formLogin,
        loader: 'bars'
      });
      try {
        const response = await this.axios.post('/kelulusan/login', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id,
          username: this.formLogin.username,
          password: this.formLogin.password,
        });
        if (response.data) {
          const data = {
            username: this.formLogin.username,
            password: this.formLogin.password,
            ...response.data
          }
          this.$store.dispatch('updateUserData', data)
        }
      } catch (err) {
        if (err.response) {
          this.$swal({
            title: 'Gagal',
            text: err.response.data.error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formLogin.password = '';
          });
        } else {
          console.log(err);
          this.$swal({
            title: 'Gagal',
            text: err.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style></style>