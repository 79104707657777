import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import bootstrapTooltip from "./components/bootstrapTooltip";

axios.defaults.withCredentials = true;

const app = createApp(App);

// axios.defaults.baseURL = "http://backend-umum.com:3029";
// app.config.globalProperties.$appUrl = "http://infokelulusan.com:209";

axios.defaults.baseURL = "https://localbackend-umum.sitepgri.com";
app.config.globalProperties.$appUrl = "https://infokelulusan.sitepgri.com";

app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(LoadingPlugin, {
    canCancel: false,
    color: "#002b80",
    backgroundColor: "#ccddff",
    opacity: 0.3,
    zIndex: 9999,
});
app.directive("tooltip", bootstrapTooltip);

app.mount("#app");
