<template>
  <main>
    <home-view />
  </main>
</template>
<script>
import HomeView from '@/views/HomeView.vue'
export default {
  name: 'App',
  components: {
    HomeView
  },
  data() {
    return {
      dataSiswa: null,
      tahunAjaran: null,
    }
  },
  computed: {
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
    username() {
      return this.$store.getters.getUsername
    },
    password() {
      return this.$store.getters.getPassword
    },
  },
  mounted() {
    let memuat = this.$loading.show({
      loader: 'spinner'
    });
    this.getTahunAjaranAktif();
    this.getWaktuUkk();
    this.getWaktuSkl();
    if (this.username && this.password) {
      this.login()
    }
    setTimeout(() => {
      memuat.hide();
    }, 250);
  },
  watch: {},
  methods: {
    async getTahunAjaranAktif() {
      try {
        const response = await this.axios.post('/kelulusan/ta-aktif', {}, {});
        if (response.data) {
          this.tahunAjaran = response.data.tahun_ajaran;
          this.$store.dispatch('updateTahunAjaran', response.data.tahun_ajaran)
        }
      }
      catch (err) {
        console.log(err);
      }
    },
    async getWaktuUkk() {
      try {
        const response = await this.axios.post('/kelulusan/waktu', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id,
          variabel: 'ukk',
        });
        if (response.data) {
          this.$store.dispatch('updateWaktuUkk', response.data.waktu)
        }
      }
      catch (err) {
        console.log(err);
      }
    },
    async getWaktuSkl() {
      try {
        const response = await this.axios.post('/kelulusan/waktu', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id,
          variabel: 'skl',
        });
        if (response.data) {
          this.$store.dispatch('updateWaktuSkl', response.data.waktu)
        }
      }
      catch (err) {
        console.log(err);
      }
    },
    async login() {
      try {
        const response = await this.axios.post('/kelulusan/login', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id,
          username: this.username,
          password: this.password,
        });
        if (response.data) {
          const data = {
            username: this.username,
            password: this.password,
            ...response.data
          }
          this.$store.dispatch('updateUserData', data)
        }
      } catch (err) {
        console.log(err);
      }
    },
  }
}
</script>
<style>
main {
  font-family: 'Trebuchet MS', sans-serif;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-image: url('./assets/foto_bersama.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay .container {
  background: rgba(255, 255, 255, 0.75);
  position: relative;
  padding: 20px;
  overflow-y: auto;
}

.overlay .container .form {
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: relative;
  z-index: 3;
}

.overlay .container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  top: 60px;
  left: 30px;
  bottom: 60px;
  right: 30px;
  background-image: url('./assets/logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}
</style>
