import { createStore } from "vuex";

export default createStore({
    state: {
        tahunAjaran: JSON.parse(localStorage.getItem("taAktif")) || {
            tahun_ajaran_id: "",
            nama: "",
        },
        username: localStorage.getItem("username") || null,
        password: localStorage.getItem("password") || null,
        userData: null,
        waktuUkk: localStorage.getItem("waktuUkk") || null,
        waktuSkl: localStorage.getItem("waktuSkl") || null,
    },
    getters: {
        getTahunAjaran(state) {
            return state.tahunAjaran;
        },
        getUsername(state) {
            return state.username;
        },
        getPassword(state) {
            return state.password;
        },
        getUserData(state) {
            return state.userData;
        },
        getWaktuUkk(state) {
            return state.waktuUkk;
        },
        getWaktuSkl(state) {
            return state.waktuSkl;
        },
    },
    mutations: {
        setTahunAjaran(state, ta) {
            state.tahunAjaran = ta;
        },
        setUsername(state, value) {
            state.username = value;
            localStorage.setItem("username", value);
        },
        setPassword(state, value) {
            state.password = value;
            localStorage.setItem("password", value);
        },
        setUserData(state, data) {
            state.userData = data;
        },
        setWaktuUkk(state, value) {
            state.waktuUkk = value;
            localStorage.setItem("waktuUkk", value);
        },
        setWaktuSkl(state, value) {
            state.waktuSkl = value;
            localStorage.setItem("waktuSkl", value);
        },
    },
    actions: {
        updateTahunAjaran({ commit }, ta) {
            commit("setTahunAjaran", ta);
            localStorage.setItem("taAktif", JSON.stringify(ta));
        },
        updateUserData({ commit }, data) {
            commit("setUsername", data.username);
            commit("setPassword", data.password);
            commit("setUserData", data.siswa);
            localStorage.setItem("username", data.username);
            localStorage.setItem("password", data.password);
        },
        logoutSiswa({ commit }) {
            commit("setUsername", null);
            commit("setPassword", null);
            commit("setUserData", null);
            localStorage.removeItem("username");
            localStorage.removeItem("password");
        },
        updateWaktuUkk({ commit }, ukk) {
            commit("setWaktuUkk", ukk);
            localStorage.setItem("waktuUkk", ukk);
        },
        updateWaktuSkl({ commit }, skl) {
            commit("setWaktuSkl", skl);
            localStorage.setItem("waktuSkl", skl);
        },
    },
    modules: {},
});
